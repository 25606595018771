const { library, config } = require('@fortawesome/fontawesome-svg-core');
const { fas } = require('@fortawesome/free-solid-svg-icons');
require('@fortawesome/fontawesome-svg-core/styles.css');
require('prism-oceanic-next/src/oceanic-next.css');
require('prismjs/plugins/diff-highlight/prism-diff-highlight.css');
require('react-typist/dist/Typist.css');
require('./src/styles/main.scss');

exports.onClientEntry = () => {
  /* Prevents fontawesome auto css insertion */
  config.autoAddCss = false;
  library.add(fas);
};
